export const ACCOUNT_LINKING_ERROR_CODES = {
  EMAIL_TAKEN_BY_ACCOUNT_WITH_TP_CRED: 'EmailTakenByAccountWithTpCredentials',
  EMAIL_TAKEN_BY_ACCOUNT_WITH_FB_CRED: 'EmailTakenByAccountWithFbCredentials',
  EMAIL_TAKEN_BY_ACCOUNT_WITH_APPLE_CRED:
    'EmailTakenByAccountWithAppleCredentials',
}

export const ADMIN_STATUS = Object.freeze({
  NONE: 'none',
  APPROVED: 'approved',
})

export const ATTENDANCE_STATUS = Object.freeze({
  PRESENT: 1,
  ABSENT: 2,
  PENDING: 3,
  RESERVE: 4,
})

export const ATTENDANCE_VISIBILITY = Object.freeze({
  ATTENDANCE_TOTAL_AND_ATTENDANCE_LIST: 1,
  ATTENDANCE_TOTAL_ONLY: 2,
  NO_ATTENDANCE_INFO: 3,
})

export const ATTENDANCE_VISIBILITIES = Object.freeze([
  {
    id: ATTENDANCE_VISIBILITY.ATTENDANCE_TOTAL_AND_ATTENDANCE_LIST,
    label: 'phrase.attendance_total_and_attendance_list',
  },
  {
    id: ATTENDANCE_VISIBILITY.ATTENDANCE_TOTAL_ONLY,
    label: 'phrase.attendance_total_only',
  },
  {
    id: ATTENDANCE_VISIBILITY.NO_ATTENDANCE_INFO,
    label: 'phrase.no_attendance_info',
  },
])

export const CLUB_GRANT_ADMIN_REQUEST = Object.freeze({
  APPROVED: 'approved',
  PENDING: 'pending',
  DECLINED: 'declined',
})

export const CLUB_ROLE = Object.freeze({
  ADMIN: 1,
})

export const CLUB_ROLES = Object.freeze([
  {
    id: CLUB_ROLE.ADMIN,
    label: 'resource.club.roles.admin',
  },
])

export const CLUB_LEGAL_STATUS = Object.freeze({
  ASSOCIATION: 1,
  BUSINESS: 2,
  SOLETRADER: 3,
})

export const CLUB_LEGAL_STATUSES = Object.freeze([
  {
    id: CLUB_LEGAL_STATUS.ASSOCIATION,
    label: 'resource.club.legal_status.association',
  },
  {
    id: CLUB_LEGAL_STATUS.BUSINESS,
    label: 'resource.club.legal_status.business',
  },
  {
    id: CLUB_LEGAL_STATUS.SOLETRADER,
    label: 'resource.club.legal_status.soletrader',
  },
])

export const EMOJI_TYPES = {
  LIKE: 1,
  DISLIKE: 2,
}

export const EVENT_TYPE = Object.freeze({
  RECURRENT: 1,
  PUNCTUAL: 2,
  MATCH: 3,
})

export const EVENT_TYPES = Object.freeze([
  {
    id: EVENT_TYPE.RECURRENT,
    label: 'resource.event.type.recurrent',
  },
  {
    id: EVENT_TYPE.PUNCTUAL,
    label: 'resource.event.type.punctual',
  },
  {
    id: EVENT_TYPE.MATCH,
    label: 'phrase.match',
  },
])

export const EVENTS_VIEW = Object.freeze({
  LIST: 'list',
  CALENDAR: 'calendar',
})

export const LEGAL_GUARDIAN_SLOT_TYPE = Object.freeze({
  LEGAL_GUARDIAN: 'LegalGuardian',
  EMAIL_INVITATION: 'EmailInvitation',
})

export const LOCALE_CODE = {
  FR: 'fr',
  EN: 'en',
  DE: 'de',
  IT: 'it',
  ES: 'es',
  PT: 'pt',
}

export const LOCALE_ID = {
  FR: 1,
  EN: 2,
  DE: 3,
  ES: 4,
  IT: 5,
  PT: 6,
}

export const LOCALE_ID_TO_CODE = {
  1: LOCALE_CODE.FR,
  2: LOCALE_CODE.EN,
  3: LOCALE_CODE.DE,
  4: LOCALE_CODE.ES,
  5: LOCALE_CODE.IT,
  6: LOCALE_CODE.PT,
}

/**
 * @see https://mangopay.com/docs/endpoints/users#legal-user-object
 */
export const MANGOPAY_USER_KYC_LEVEL = Object.freeze({
  LIGHT: 'LIGHT',
  REGULAR: 'REGULAR',
})

/**
 * @see https://mangopay.com/docs/concepts/users/verification/document-process#overview
 */
export const MANGOPAY_DOCUMENT_STATUS = Object.freeze({
  CREATED: 'CREATED',
  VALIDATION_ASKED: 'VALIDATION_ASKED',
  VALIDATED: 'VALIDATED',
  REFUSED: 'REFUSED',
  OUT_OF_DATE: 'OUT_OF_DATE',
})

/**
 * @see https://mangopay.com/docs/concepts/users/verification/document-process#dealing-with-refusals
 */
export const MANGOPAY_DOCUMENT_REFUSED_REASON = Object.freeze({
  DOCUMENT_DO_NOT_MATCH_USER_DATA: 'document_do_not_match_user_data',
  DOCUMENT_FALSIFIED: 'document_falsified',
  DOCUMENT_HAS_EXPIRED: 'document_has_expired',
  DOCUMENT_INCOMPLETE: 'document_incomplete',
  DOCUMENT_MISSING: 'document_missing',
  DOCUMENT_NOT_ACCEPTED: 'document_not_accepted',
  DOCUMENT_UNREADABLE: 'document_unreadable',
  SPECIFIC_CASE: 'specific_case',
  UNDERAGE_PERSON: 'underage_person',
})

export const MANGOPAY_MANDATE_STATUS = Object.freeze({
  CREATED: 'CREATED',
  SUBMITTED: 'SUBMITTED',
  ACTIVE: 'ACTIVE',
  FAILED: 'FAILED',
  EXPIRED: 'EXPIRED',
})

export const MANGOPAY_TRANSACTION_STATUS = Object.freeze({
  CREATED: 'CREATED',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
})

export const MANGOPAY_PAYOUT_NATURE = Object.freeze({
  REGULAR: 'REGULAR',
  REPUDIATION: 'REPUDIATION',
  REFUND: 'REFUND',
  SETTLEMENT: 'SETTLEMENT',
})

export const MEMBERSHIP_TYPE = Object.freeze({
  CLUB: 'club',
  TEAM: 'team',
})

export const PLAYER_LATERALITY = Object.freeze({
  RIGHT: 1,
  LEFT: 2,
})

export const PLAYER_LATERALITIES = Object.freeze([
  { id: PLAYER_LATERALITY.RIGHT, label: 'phrase.right' },
  { id: PLAYER_LATERALITY.LEFT, label: 'phrase.left' },
])

export const PLAYER_ROLES_ID = Object.freeze({
  GUEST: 1,
  MEMBER: 2,
  ADMIN: 3,
})

export const PLAYER_ROLES = Object.freeze([
  {
    id: PLAYER_ROLES_ID.GUEST,
    label: 'phrase.guest',
  },
  {
    id: PLAYER_ROLES_ID.MEMBER,
    label: 'phrase.one_member',
  },
  {
    id: PLAYER_ROLES_ID.ADMIN,
    label: 'phrase.admin',
  },
])

export const PLAYER_TYPE = Object.freeze({
  CHILD: 'child',
  LEGAL_GUARDIAN_AND_PLAYER: 'legal_guardian_and_player',
  LEGAL_GUARDIAN_ONLY: 'legal_guardian_only',
  PLAYER_ONLY: 'player_only',
})

export const PAYMENT_METHOD_CODE = Object.freeze({
  OFFLINE_CARD: 'offline_card',
  ONLINE_CARD: 'online_card',
  CASH: 'cash',
  CHEQUE: 'cheque',
  TRANSFER: 'transfer',
  DIRECT_DEBIT: 'direct_debit',
})

export const PAYMENT_STATUS = Object.freeze({
  WAITING: 1,
  PAID: 2,
  FAILED: 3,
})

export const PAYMENT_STATUSES = Object.freeze([
  {
    id: PAYMENT_STATUS.PAID,
    label: 'input.payment.status.paid',
  },
  {
    id: PAYMENT_STATUS.WAITING,
    label: 'phrase.awaiting',
  },
  {
    id: PAYMENT_STATUS.FAILED,
    label: 'input.payment.status.failed',
  },
])

export const SIGN_UP_ERROR_CODES = {
  USE_TEAMPULSE_CONNECT: 'UseTeamPulseConnectInstead',
  USE_FACEBOOK_CONNECT: 'UseFacebookConnectInstead',
  USE_APPLE_CONNECT: 'UseAppleConnectInstead',
}

export const TIMEZONE = {
  EUROPE_PARIS: 'Europe/Paris',
}
export const TIMEZONE_ID = Object.freeze({
  EUROPE_PARIS: 461,
})
