import { request } from '~/utils/request'

export const actions = {
  /**
   * Retrieve the list of legal guardian slots for a given child player.
   * A slot can be filled with a legal guardian or a pending legal guardian invitation.
   * @param {Number} childId the child player identifier.
   * @param {Number} clubId the club identifier.
   * @returns {Promise<Array<Object>>}
   */
  async getLegalGuardians(_, { clubId, childId }) {
    const { data } = await request(this).get(
      '/clubs/{clubId}/children/{childId}/legal-guardians',
      { childId, clubId }
    )
    return data
  },
}
