
import ClubPicture from '~/components/molecules/clubs/ClubPicture.vue'
import { getSportNameAttribute } from '~/utils/data'

export default {
  components: { ClubPicture },

  props: {
    club: {
      type: Object,
      required: true,
    },
    size: {
      type: [String, Number],
      default: 100,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    clubDetails() {
      return [
        this.club.sport ? this.club.sport[this.sportNameProp] : null,
        this.club.town?.name,
      ]
        .filter(Boolean)
        .join(' - ')
    },

    /**
     * The sport name property depends on the locale.
     */
    sportNameProp() {
      return getSportNameAttribute(this)
    },
  },
}
