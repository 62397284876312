import isPlainObject from 'lodash/isPlainObject'
import { TIMEZONE, TIMEZONE_ID } from '~/models/enums'

/**
 * Check if a string value is empty.
 * Is considered empty:
 *   - null
 *   - undefined
 *   - ''
 *   - string composed only of spaces
 *   - empty array (`[]`)
 *   - object with no key (`{}`)
 * @param {*} value the value to check.
 * @returns {Boolean}
 */
export const isEmpty = (value) => {
  const emptyString =
    typeof value === 'undefined' ||
    value === null ||
    value === '' ||
    (typeof value === 'string' && value.trim().length === 0)

  const emptyArray = Array.isArray(value) && value.length === 0
  const emptyObject = isObject(value) && Object.keys(value).length === 0

  return emptyString || emptyArray || emptyObject
}

/**
 * Check if a variable is a native javascipt object (`{}`).
 * @param {*} value the variable to test.
 * @returns {boolean}
 * @see https://stackoverflow.com/a/16608074/9925718
 */
export const isObject = isPlainObject

/**
 * Check if current browser supports number format.
 */
export const hasNumberFormat = !!(
  typeof Intl === 'object' &&
  Intl &&
  typeof Intl.NumberFormat === 'function'
)

/**
 * Format date.
 * @param {self} VueComponent the component requesting for date format.
 * @param {Date} date the date to format.
 * @returns {String}
 */
export const formatDate = (self, date) => {
  const locale = self.$i18n.locale
  const newDate = new Date(date)
  return newDate.toLocaleDateString(locale)
}

/**
 * Format a number for a given local.
 * @param {Number} value the value to format.
 * @param {String} locale the current locale.
 * @returns {String}
 */
export const formatNumber = (value, locale) => {
  if (typeof value !== 'number') {
    return value
  }

  if (hasNumberFormat) {
    return new Intl.NumberFormat(locale, {
      style: 'decimal',
      maximumFractionDigits: 20,
    }).format(value)
  }

  return value
}

/**
 * Format a price for a given local, without displaying the currency.
 * @param {Number} value the value to format.
 * @param {String} locale the current locale.
 * @returns {String}
 */
export const formatMoney = (value, locale) => {
  if (hasNumberFormat) {
    return new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)
  }

  return value
}

/**
 * Format number with currency.
 * @param {Number} value the number to format.
 * @param {String} locale the current locale.
 * @param {String} currency the currency code (EUR, USD, etc.)
 * @returns {String}
 */
export const formatCurrency = (value, locale, currency = 'EUR') => {
  if (!currency) {
    return formatNumber(value, locale)
  }

  if (hasNumberFormat) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).format(value)
  }

  return `${value} ${currency}`
}

/**
 * Whether if the app ca copy data to the clipboard.
 * @returns {Boolean}
 * @async
 */
export const canCopyClipboard = async () => {
  try {
    const result = await navigator.permissions.query({
      name: 'clipboard-write',
    })
    return ['granted', 'prompt'].includes(result.state)
  } catch (error) {
    /**
     * Two possible issues (mainly Safari and IE):
     * - `navigator.permissions` not defined
     * - permission `clipboard-write` unknown
     */
    return Boolean(navigator?.clipboard?.writeText)
  }
}

/**
 * Get the timezone from the browser.
 * Compatible with modern browsers.
 * @returns {String} the timezone name.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions
 */
export const getBrowserTimezone = () =>
  typeof Intl === 'object'
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : null

/**
 * Round a number.
 * @param {Number|String} value the number to round.
 * @param {Number} decimals the number of decimals to use to round.
 * @returns {Number}
 */
export const round = (value, decimals = 2) =>
  Number(value).toFixed(decimals) * 1

/**
 * Calculate average value of a list of values.
 * @param {Array} values a list of values.
 * @param {Number} decimals whether to round the result with a number of decimals.
 * @returns {Number|NaN} the average value, or `NaN` if the values list is empty.
 */
export const average = (values, decimals = undefined) => {
  if (!values.length) {
    return NaN
  }

  const avg = values.reduce((acc, val) => acc + val, 0) / values.length
  return typeof decimals !== 'undefined' ? round(avg, decimals) : avg
}

/**
 * Build a picture url from its url with security token and authorization token.
 * @param {String|Object} picture the picture data or the picture url.
 * @param {String} updatedAt the last update of the related object. If provided, will be added as query param to force cache invalidation.
 * @returns {String}
 */
export const buildPictureUrl = (picture, updatedAt) => {
  if (typeof picture === 'string') {
    if (updatedAt) {
      try {
        const url = new URL(picture)
        return `${url}${url.search ? '&' : '?'}updated_at=${updatedAt}`
      } catch (error) {
        // not an url, don't insert date
      }
    }

    return picture
  }

  if (
    picture &&
    picture.host &&
    picture.x_amz_content_sha256 &&
    picture.x_amz_security_token
  ) {
    return `${picture.host}?x_amz_content_sha256=${picture.x_amz_content_sha256}&x_amz_security_token=${picture.x_amz_security_token}`
  }

  if (picture) {
    return URL.createObjectURL(picture)
  }

  return undefined
}

/**
 * Build user full name from its profile.
 * @param {Object} user the user.
 * @returns {String}
 */
export const buildUserFullName = (user) =>
  user ? `${user.first_name || ''} ${user.last_name || ''}`.trim() : undefined

/**
 * Get the attribute to use to display a translated name, depending on current locale.
 * @param {VueComponent} self the component requesting for the name.
 * @returns {String}
 */
export const getI18nName = (self) => {
  return `name_${self.$i18n.locale}`
}

/**
 * Get the attribute to use to display a sport name, depending on current locale.
 * Use this helper because there is `name_en` and no `name_fr`.
 * @param {VueComponent} self the component requesting for sport name.
 * @returns {String}
 */
export const getSportNameAttribute = (self) => {
  const locale = self.$i18n.locale
  const availableLocales = self.$config.apiLocales.filter(
    // there is no `name_fr` in sports (but there is in sport/positions!)
    (locale) => locale.toLowerCase() !== 'fr'
  )

  if (availableLocales.includes(locale)) {
    return `name_${locale}`
  }

  return 'name'
}

/**
 * Get the message related to the error returned by an HTTP response.
 * @param {Object} error the error.
 * @param {Object|Function} responses can be:
 * - a list of error messages overriding the default error messages.
 * - a callback to call to get dynamic messages depending of response.Prototype should be `(status, data) => {}`.
 * @returns {String}
 */
export const getMessageFromErrorResponse = (error, responses) => {
  console.error(error)
  const status = error.response?.status

  if (responses) {
    if (typeof responses === 'function') {
      const message = responses(status, error.response?.data || {})
      if (message) {
        return message
      }
    } else if (typeof responses === 'object' && responses[status]) {
      return responses[status]
    }
  }

  switch (status) {
    case 400:
      return 'phrase.error_400_title'
    case 403:
      return 'phrase.error_403_title'
    case 404:
      return 'phrase.not_found'
    default:
      return 'phrase.error_unknown'
  }
}

/**
 * Whether if the timezone is the french one.
 * @param {Object} timezones the timezones.
 * @param {String} timezoneId the id of the timezone that will be checked.
 * @returns {Boolean}
 */
export const isFrenchTimezone = (timezones, timezoneId) => {
  const frenchTz = timezones.find((t) => t.name === TIMEZONE.EUROPE_PARIS)
  return frenchTz && frenchTz.id === timezoneId
}

/**
 * Whether if the timezone is the french one.
 * Check is made regarding the timezone ID.
 * @param {String|Number} timezoneId the id of the timezone that will be checked.
 * @returns {Boolean}
 */
export const isFrenchTimezoneId = (timezoneId) => {
  return parseInt(timezoneId) === TIMEZONE_ID.EUROPE_PARIS
}

/**
 * Get the extension of a file name.
 *
 * ""                            -->   ""
 * "name"                        -->   ""
 * "name.txt"                    -->   "txt"
 * ".htpasswd"                   -->   ""
 * "name.with.many.dots.myext"   -->   "myext"
 *
 * @param {String} fname the file name.
 * @returns {String} the extension if any or an empty string.
 */
export const getFileNameExtension = (fname) =>
  fname.slice((Math.max(0, fname.lastIndexOf('.')) || Infinity) + 1)

/**
 * Build a translatable token from a source token.
 * Use this for inputs to simplify the component properties.
 * @param {String} source the source token.
 * @returns {String}
 */
export const buildSourceLabelToken = (source) => {
  if (source) {
    const parts = source.split('.')
    if (parts.length === 1) {
      return `resource.${parts[0]}.name`
    }

    return `resource.${parts[0]}.fields.${parts.slice(1)}`
  }
}

const fakeRandom = (nbr) => {
  // eslint-disable-next-line unicorn/number-literal-case
  let t = (nbr += 0x6d2b79f5)
  t = Math.imul(t ^ (t >>> 15), t | 1)
  t ^= t + Math.imul(t ^ (t >>> 7), t | 61)
  return (((t ^ (t >>> 14)) >>> 0) / 4294967296) * 256
}

/**
 * Get a color for an event.
 * @param {Number} eventId the event unique identifier.
 * @returns {String} the RGB color CSS property.
 */
export const getEventRgbColor = (eventId) =>
  `rgb(${fakeRandom(eventId)} ${fakeRandom(eventId + 1)} ${fakeRandom(
    eventId + 2
  )})`

export const isAntiAdsAvailable = (club, $config) => {
  const allowedTimezones = $config.antiAds.timezoneIdAllowed
  return allowedTimezones.includes(club?.timezone_id)
}

export const isOnlinePaymentAvailable = (club, $config) => {
  const allowedTimezones = $config.online_payment.timezone_id_allowed
  return allowedTimezones.includes(club?.timezone_id)
}
