
/**
 * This grid has the same behavior as `v-row` and `v-col`.
 * You can select the space between items with `noGutters`/`dense` properties (like `v-row`).
 * Each item with can be set using `xl`, `lg`, `md`, `sm`, `xs`, `cols` properties (like `v-col`),
 * the number of items per row will be calculated from them.
 */
export default {
  props: {
    areas: {
      type: [String, Array],
      default: null,
    },
    cols: {
      type: [Number, String],
      default: 12,
    },
    xs: {
      type: [Number, String],
      default: null,
    },
    sm: {
      type: [Number, String],
      default: null,
    },
    md: {
      type: [Number, String],
      default: null,
    },
    lg: {
      type: [Number, String],
      default: null,
    },
    xl: {
      type: [Number, String],
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    noGutters: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    /**
     * Get grid template depending on the screen size.
     */
    customGridCss() {
      let gridTemplateAreas
      if (this.areas) {
        const isArray = Array.isArray(this.areas)
        const isArrayOfArray =
          isArray && this.areas.length && Array.isArray(this.areas[0])

        gridTemplateAreas = isArrayOfArray
          ? this.areas.map((line) => `"${line.join(' ')}"`).join(' ')
          : isArray
          ? `"${this.areas.join(' ')}"`
          : this.areas
      }

      return {
        gridTemplateColumns: `repeat(${this.nbPerRow}, 1fr)`,
        gridTemplateAreas,
      }
    },

    /**
     * The gap to apply to the grid.
     * @returns {String} the class name to apply.
     */
    gapClass() {
      switch (true) {
        case this.noGutters:
          return 'a-grid__no-gutters'

        case this.dense:
          return 'a-grid__dense'

        default:
          return null
      }
    },

    /**
     * The number of items per row.
     * @returns {Number}
     */
    nbPerRow() {
      const breakpoints = ['xl', 'lg', 'md', 'sm', 'xs', 'cols'] // from taller to smaller
      const breakpointIndex = breakpoints.indexOf(this.$vuetify.breakpoint.name)

      // get closest breakpoint width defined
      const breakpoint = breakpoints
        .slice(breakpointIndex)
        .find((bp) => this[bp])

      // transform item width to number of items per row
      return breakpoint ? Math.floor(this.cols / this[breakpoint]) : 1
    },
  },
}
