
import { CircleStencil, Cropper, RectangleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import TInput from '~/components/atoms/input/TInput.vue'
import { buildPictureUrl, round } from '~/utils/data'

export default {
  components: { Cropper, TInput },

  props: {
    color: {
      type: String,
      default: 'white',
    },
    height: {
      type: [String, Number],
      default: 125,
    },
    rules: {
      type: Array,
      default: undefined,
    },
    stencil: {
      type: String,
      default: 'circle',
    },
    value: {
      type: [String, File, Blob, Object],
      default: null,
    },
    width: {
      type: [String, Number],
      default: 125,
    },
  },

  data() {
    return {
      dialogOpen: false,
      image: null,
      imageBuffer: null,
    }
  },

  computed: {
    stencilComponent() {
      return this.stencil === 'circle' ? CircleStencil : RectangleStencil
    },
    stencilProps() {
      return {
        aspectRatio: 1,
        handlersClasses: { default: 'a-imageCropperInput__cropper__handler' },
      }
    },
    url() {
      return buildPictureUrl(this.value)
    },
  },

  watch: {
    image: {
      immediate: true,
      /**
       * Read file if provided.
       * @param {File} val the uploaded file.
       */
      handler(val) {
        if (!val) {
          return
        }

        const fs = new FileReader()
        fs.addEventListener('load', () => (this.imageBuffer = fs.result), false)
        fs.readAsDataURL(val)
      },
    },
  },

  methods: {
    /**
     * Confirm file crop and close dialog.
     */
    async onDialogConfirmClick() {
      const { canvas } = this.$refs.cropper.getResult()

      if (canvas) {
        const data = await new Promise((resolve) => {
          canvas.toBlob((blob) => {
            resolve(blob)
          })
        })

        this.$emit('input', new File([data], this.image.name))
      } else {
        // on some old android devices, canvas can be empty ¯\_(ツ)_/¯
        this.$notify({ message: 'phrase.error_unknown', type: 'error' })
      }

      this.dialogOpen = false
    },

    /**
     * Close dialg without using the image.
     */
    onDialogCancelClick() {
      this.dialogOpen = false
      this.image = false
    },

    /**
     * When file is selected, open cropper dialog.
     * @param {Event} e the event.
     */
    onInputChange(e) {
      const image = e?.target?.files && e.target.files[0]

      if (!image) {
        return
      }

      if (
        this.$config.cropperImageUploadMaxSize &&
        image.size > this.$config.cropperImageUploadMaxSize * 1024 * 1024
      ) {
        this.$notify({
          message: this.$t('error.common.upload_size_exceeded', [
            round(this.$config.cropperImageUploadMaxSize, 2),
          ]),
          type: 'error',
        })
        return
      }

      // open dialog
      this.dialogOpen = true
      this.image = image
      this.$refs.input.value = ''
    },

    /**
     * Open file selector when clicking on the button.
     */
    onPlaceholderEditClick() {
      this.$refs.input.click()
    },

    /**
     * Clear file input.
     */
    onPlaceholderRemoveClick() {
      this.$emit('input', null)
      this.image = null
    },
  },
}
