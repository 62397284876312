
import TInput from '~/components/atoms/input/TInput.vue'

export default {
  components: { TInput },

  props: {
    menuProps: {
      type: Object,
      default: () => ({ offsetY: true }),
    },
  },

  computed: {
    allProps() {
      return { ...this.$attrs, ...this._props }
    },
  },
}
