import Vue from 'vue'
import { VAlert } from 'vuetify/lib/components'

import BackButton from '~/components/atoms/BackButton'
import BackLink from '~/components/atoms/BackLink'
import CircleCheckbox from '~/components/atoms/input/CircleCheckbox'
import Grid from '~/components/atoms/Grid'
import ImageCropperInput from '~/components/atoms/input/ImageCropperInput'
import MenuItems from '~/components/atoms/MenuItems'
import TAutocomplete from '~/components/atoms/input/TAutocomplete'
import TBtn from '~/components/atoms/TBtn'
import TCheckbox from '~/components/atoms/input/TCheckbox'
import TCombobox from '~/components/atoms/input/TCombobox'
import TDatePicker from '~/components/atoms/input/TDatePicker'
import TDialog from '~/components/atoms/TDialog'
import TDialogCard from '~/components/atoms/TDialogCard'
import TDialogCardForm from '~/components/atoms/TDialogCardForm'
import TFileInput from '~/components/atoms/input/TFileInput'
import TSelect from '~/components/atoms/input/TSelect'
import TText from '~/components/atoms/TText'
import TTextField from '~/components/atoms/input/TTextField'
import TTextarea from '~/components/atoms/input/TTextarea'
import TTimePicker from '~/components/atoms/input/TTimePicker'
import TForm from '~/components/atoms/form/TForm'
import TFormValidator from '~/components/atoms/form/TFormValidator'
import CircleCheckboxEmpty from '~/components/atoms/icons/CircleCheckboxEmpty'
import CircleCheckboxMarked from '~/components/atoms/icons/CircleCheckboxMarked'
import IconAds from '~/components/atoms/icons/IconAds'
import IconDislike from '~/components/atoms/icons/IconDislike'
import IconEvent from '~/components/atoms/icons/IconEvent'
import IconLike from '~/components/atoms/icons/IconLike'
import IconMatch from '~/components/atoms/icons/IconMatch'
import IconPlusOrange from '~/components/atoms/icons/IconPlusOrange'
import IconRecurrentEvent from '~/components/atoms/icons/IconRecurrentEvent'
import Alert from '~/components/molecules/Alert'
import Confirm from '~/components/molecules/Confirm'
import DeleteConfirm from '~/components/molecules/DeleteConfirm'
import DeleteConfirmCode from '~/components/molecules/DeleteConfirmCode'

/**
 * Atoms
 */
Vue.component('BackButton', BackButton)
Vue.component('BackLink', BackLink)
Vue.component('CircleCheckbox', CircleCheckbox)
Vue.component('CircleCheckboxEmpty', CircleCheckboxEmpty)
Vue.component('CircleCheckboxMarked', CircleCheckboxMarked)
Vue.component('Grid', Grid)
Vue.component('MenuItems', MenuItems)
Vue.component('ImageCropperInput', ImageCropperInput)
Vue.component('IconLike', IconLike)
Vue.component('IconDislike', IconDislike)
Vue.component('IconPlusOrange', IconPlusOrange)
Vue.component('IconEvent', IconEvent)
Vue.component('IconRecurrentEvent', IconRecurrentEvent)
Vue.component('IconMatch', IconMatch)
Vue.component('IconAds', IconAds)

/**
 * Molecules
 */
Vue.component('Alert', Alert)
Vue.component('Confirm', Confirm)
Vue.component('DeleteConfirm', DeleteConfirm)
Vue.component('DeleteConfirmCode', DeleteConfirmCode)

/**
 * Override Vuetify components.
 * Use the same name as vuetify component but with `t-` prefix.
 */
Vue.component('TAlert', {
  extends: VAlert,
  props: {
    dense: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: true,
    },
  },
})
Vue.component('TAutocomplete', TAutocomplete)
Vue.component('TBtn', TBtn)
Vue.component('TCheckbox', TCheckbox)
Vue.component('TCombobox', TCombobox)
Vue.component('TDatePicker', TDatePicker)
Vue.component('TDialog', TDialog)
Vue.component('TDialogCard', TDialogCard)
Vue.component('TDialogCardForm', TDialogCardForm)
Vue.component('TFileInput', TFileInput)
Vue.component('TForm', TForm)
Vue.component('TFormValidator', TFormValidator)
Vue.component('TSelect', TSelect)
Vue.component('TTextField', TTextField)
Vue.component('TText', TText)
Vue.component('TTextarea', TTextarea)
Vue.component('TTimePicker', TTimePicker)
