import { request } from '~/utils/request'

/* eslint-disable camelcase */
/**
 * Remove values that should not be sent to the server when creating/updating a team.
 * @param {Object} data the team data.
 * @returns {Object}
 */
const sanitizeTeam = ({
  name,
  category_id,
  code,
  timezone_id,
  town_id,
  is_team_pic_uploaded,
}) => {
  const res = {
    name,
    category_id,
    code: code || '',
    timezone_id,
    town_id,
    is_team_pic_uploaded: is_team_pic_uploaded || false,
  }
  if (!town_id) {
    delete res.town_id
  }
  return res
}
/* eslint-enable camelcase */

/**
 * Default state.
 * @returns {Object}
 */
export const state = () => ({
  awaitingList: {
    fetchedOnce: false,
    ids: [],
    data: {},
  },
  list: {
    fetchedOnce: false,
    ids: [],
    data: {},
  },
})

export const actions = {
  /**
   * List invited teams.
   * @param {Number} clubId the club unique identifier.
   * @param {Number} q the filter params.
   * @returns {Promise<Array>}
   */
  async fetchAwaitingList({ commit }, { clubId, q }) {
    const { data } = await request(this, 'club').get(
      'clubs/{clubId}/awaiting-teams',
      {
        clubId,
      },
      { q }
    )

    commit('setAwaitingList', data)
    commit('setAwaitingListFetchedOnce', true)

    return data
  },

  /**
   * List all teams.
   * @param {Number} clubId the club unique identifier.
   * @param {Number} q the filter params.
   * @returns {Promise}
   */
  async fetchList({ commit }, { clubId, q }) {
    const { data } = await request(this, 'club').get(
      'clubs/{clubId}/teams',
      {
        clubId,
      },
      { q }
    )

    commit('setList', data)
    commit('setListFetchedOnce', true)

    return data
  },

  /**
   * Retrieve a team by its ID.
   * @param {Number} id the team unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchOne({ commit }, id) {
    const { data } = await request(this).get('teams/{teamId}', { teamId: id })

    commit('upsertTeamInList', data)

    return data
  },

  /**
   * Retrieve a team with a player membership.
   * If the player is not member of the team, the request will fail
   * even if the player is club admin.
   *
   * @param {Number} teamId the team identifier.
   * @param {Number} playerId the player identifier.
   * @returns {Object} the team, with a nested membership.
   */
  async fetchOneWithMembership({ commit }, { teamId, playerId }) {
    const { data } = await request(this).get(
      '/players/{playerId}/teams/{teamId}',
      { teamId, playerId }
    )

    commit('upsertTeamInList', data)

    return data
  },

  /**
   * Retrieve a team by its code.
   * @param {Number} code the code to find a team.
   * @returns {Promise<Object>} the team.
   */
  async fetchOneByCode(_, { code }) {
    const { data } = await request(this).get('/teams/code/{code}', {
      code: encodeURIComponent(code),
    })
    return data
  },

  /**
   * Fetch all teams from a player in a club.
   * @param {Number} clubId the club unique identifier.
   * @param {Number} playerId the player unique identifier.
   * @returns {Promise<Object>}
   */
  async fetchAllPlayerTeamsFromClub(_, { clubId, playerId }) {
    const { data } = await request(this, 'club').get(
      '/clubs/{clubId}/players/{playerId}/teams',
      {
        clubId,
        playerId,
      }
    )
    return data
  },

  /**
   * List all teams of a player using the REST api.
   * @param {Number} playerId the player identifier.
   * @returns {Promise<Array<Object>>} a list of teams.
   */
  async fetchAllPlayerTeamsFromRest(_, { playerId }) {
    /**
     * FIXME: uncomment this when the endpoint will be available.
    const { data } = await request(this).get('/players/{playerId}/teams', {
      playerId,
    })
    */
    const data = await Promise.resolve([])
    /* /FIXME */
    return data
  },

  /**
   * Create a Team from a club.
   * @param {Object} values the team values.
   * @param {Number} clubId the club unique identifier.
   * @returns {Promise<Object>} the created team.
   */
  async create({ commit, dispatch }, { values, clubId }) {
    let payload = sanitizeTeam(values)

    if (values.team_logo) {
      const imageData = await dispatch(
        'files/uploadTeamPicture',
        { image: values.team_logo },
        { root: true }
      )

      payload = { ...payload, ...imageData }
    }

    const result = await request(this, 'club').post(
      '/clubs/{clubId}/teams',
      { clubId },
      payload
    )

    commit('upsertTeamInList', result)

    return result
  },

  /**
   * Delete a team.
   * @param {Number} teamId the team unique identifier.
   * @returns {Promise}
   */
  async delete({ commit }, { teamId }) {
    const result = await request(this).delete('teams/{teamId}', {
      teamId,
    })

    commit('removeTeamFromList')

    return result
  },

  /**
   * Update a team.
   * @param {Object} values the team values.
   * @returns {Promise<Object>} the updated team.
   */
  async update({ commit, dispatch }, values) {
    let payload = sanitizeTeam(values)

    if (values.team_logo) {
      const imageData = await dispatch(
        'files/uploadTeamPicture',
        { image: values.team_logo, uuid: values.team_picture_id },
        { root: true }
      )

      payload = { ...payload, ...imageData }
    }

    const result = await request(this).put(
      '/teams/{teamId}',
      {
        teamId: values.id,
      },
      payload
    )

    commit('upsertTeamInList', result)

    return result
  },

  /**
   * Withdraw a team of a club.
   * @param {Number} teamId the team id values.
   * @param {Number} clubId the club id values.
   * @returns {Promise<Object>}
   */
  async withdraw({ commit }, { teamId, clubId }) {
    const { data } = await request(this, 'club').delete(
      '/clubs/{clubId}/teams/{teamId}',
      { clubId, teamId }
    )

    commit('removeTeamFromList', teamId)

    return data
  },

  /**
   * Delete a club invitation for a team.
   * @returns {Promise}
   */
  async deleteClubInvitation({ commit }, { teamId, clubId }) {
    const { data } = await request(this, 'club').delete(
      '/clubs/{clubId}/awaiting-teams/{teamId}/club-invitation',
      { clubId, teamId }
    )

    commit('removeTeamFromAwaitingList', teamId)

    return data
  },

  /**
   * Inivte a team in a club.
   * @param {Number} teamId the team id values.
   * @param {Number} clubId the club id values.
   * @returns {Promise<Object>}
   */
  async invite(_, { teamId, clubId }) {
    const { data } = await request(this, 'club').post(
      '/clubs/{clubId}/teams/invite',
      { clubId },
      { team_id: teamId }
    )
    return data
  },
}

export const getters = {
  /**
   * Get list of awaiting teams.
   * @param {Object} state the current state.
   * @returns {Array<Object>}
   */
  getAwaitingList(state) {
    return state.awaitingList.ids.map((id) => state.awaitingList.data[id])
  },

  /**
   * Get list of validated teams.
   * @param {Object} state the current state.
   * @returns {Array<Object>}
   */
  getList(state) {
    return state.list.ids.map((id) => state.list.data[id])
  },

  /**
   * Retrieve a team from its ID.
   * @param {Object} state the current state.
   * @param {Number} teamId the team unique identifier.
   * @returns {Object}
   */
  getTeam: (state) => (teamId) => {
    return (
      state.list.data[teamId] || state.awaitingList.data[teamId] || undefined
    )
  },

  /**
   * Check if list of awaiting teams has been loaded at least once.
   * @param {Object} state the current state.
   * @returns {Boolean}
   */
  isAwaitingListFetchedOnce(state) {
    return state.awaitingList.fetchedOnce
  },

  /**
   * Check if teams list has been loaded at least once.
   * @param {Object} state the current state.
   * @returns {Boolean}
   */
  isListFetchedOnce(state) {
    return state.list.fetchedOnce
  },
}

export const mutations = {
  /**
   * Remove a team from the awaiting list.
   * @param {Object} state the current state.
   * @param {Number} teamId the identifier of the team to remove.
   */
  removeTeamFromAwaitingList(state, teamId) {
    state.awaitingList.ids = state.awaitingList.ids.filter(
      (id) => id !== teamId
    )
  },

  /**
   * Remove a team from the list.
   * @param {Object} state the current state.
   * @param {Number} teamId the identifier of the team to remove.
   */
  removeTeamFromList(state, teamId) {
    state.list.ids = state.list.ids.filter((id) => id !== teamId)
  },

  /**
   * Set the list of awaiting teams.
   * @param {Object} state the current state.
   * @param {Array<Object>} teams a list of teazms.
   */
  setAwaitingList(state, teams) {
    const { ids, data } = teams.reduce(
      (acc, team) => ({
        ids: [...acc.ids, team.id],
        data: { ...acc.data, [team.id]: team },
      }),
      { ids: [], data: {} }
    )
    state.awaitingList.data = data
    state.awaitingList.ids = ids
  },

  /**
   * Mark awaiting list as fetched at least once.
   * @param {Object} state the current state.
   * @param {Boolean} fetched whether if the list is considered fetched.
   */
  setAwaitingListFetchedOnce(state, fetched) {
    state.awaitingList.fetchedOnce = fetched
  },

  /**
   * Set the list of teams.
   * @param {Object} state the current state.
   * @param {Array<Object>} teams a list of teazms.
   */
  setList(state, teams) {
    const { ids, data } = teams.reduce(
      (acc, team) => ({
        ids: [...acc.ids, team.id],
        data: { ...acc.data, [team.id]: team },
      }),
      { ids: [], data: {} }
    )
    state.list.data = data
    state.list.ids = ids
  },

  /**
   * Mark list as fetched at least once.
   * @param {Object} state the current state.
   * @param {Boolean} fetched whether if the list is considered fetched.
   */
  setListFetchedOnce(state, fetched) {
    state.list.fetchedOnce = fetched
  },

  /**
   * Add a team in the list, or update it if already in the list.
   * @param {Object} state the current state.
   * @param {Object} team the team to add to the list.
   */
  upsertTeamInList(state, team) {
    const index = state.list.ids.findIndex((id) => id === team.id)

    state.list.data = { ...state.list.data, [team.id]: team }
    if (index > -1) {
      state.list.ids = [...state.list.ids, team.id]
    }
  },
}
